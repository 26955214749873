const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	HEADER_HEIGHT: 70,
	TABLE_PAGE_SIZES: [15, 50, 100],
	DEFAULT_LANGUAGE: 'PL',
	ADMIN_DEFAULT_ROLE_ID: '492d63fe-b6af-49bd-ba97-0eda4fc078c4',
	DESCRIPTION_MAX_LENGTH: 10_000,
	PRODUCT_MAX_PRICE: 100_000,
	BALLOON_MIN_CREWMEN: 1,
	BALLOON_MAX_CREWMEN: 10,
	PASSANGER_MIN_AGE: 4,
	PASSANGER_MIN_WEIGHT: 25,
	PASSANGER_MAX_WEIGHT: 150,
	SESSION_STORAGE_KEYS: {
		REFRESH_TOKEN: 'refreshToken',
		USERS: {
			PAGE: 'users-page',
			LIMIT: 'users-limit',
			SEARCH: 'users-search',
			SORT: 'users-sort',
		},
		CLIENTS: {
			PAGE: 'clients-page',
			LIMIT: 'clients-limit',
			SEARCH: 'clients-search',
			SORT: 'clients-sort',
		},
		PRODUCTS: {
			PAGE: 'products-page',
			LIMIT: 'products-limit',
			SEARCH: 'products-search',
			SORT: 'products-sort',
		},
		DISCOUNT_CODES: {
			PAGE: 'discount-codes-page',
			LIMIT: 'discount-codes-limit',
			SEARCH: 'discount-codes-search',
			SORT: 'discount-codes-sort',
		},
		VOUCHERS: {
			PAGE: 'vouchers-page',
			LIMIT: 'vouchers-limit',
			SEARCH: 'vouchers-search',
			SORT: 'vouchers-sort',
		},
		USER_VOUCHERS: {
			PAGE: 'user-vouchers-page',
			LIMIT: 'user-vouchers-limit',
			SEARCH: 'user-vouchers-search',
			SORT: 'user-vouchers-sort',
		},
		CYLINDERS: {
			PAGE: 'cylinders-page',
			LIMIT: 'cylinders-limit',
			SEARCH: 'cylinders-search',
			SORT: 'cylinders-sort',
		},
		CREWMEN: {
			PAGE: 'crewmen-page',
			LIMIT: 'crewmen-limit',
			SEARCH: 'crewmen-search',
			SORT: 'crewmen-sort',
		},
		FLIGHTS: {
			PAGE: 'flights-page',
			LIMIT: 'flights-limit',
			SEARCH: 'flights-search',
			SORT: 'flights-sort',
		},
		ORDERS: {
			PAGE: 'orders-page',
			LIMIT: 'orders-limit',
			SEARCH: 'orders-search',
			SORT: 'orders-sort',
		},
	},
	CREWMAN_ROLE_ID: '3c9b4507-aa12-4724-8f78-81d1bba7dd39',
	PILOT_ROLE_ID: 'b24ded99-1095-4cde-a842-71a85bfa59a0',
	USER_VOUCHER_EXPIRATION_TRESCHOLD: 3,
	LIFTING_CARD_MIN_TEMP: 0,
	LIFTING_CARD_MAX_TEMP: 39,
} as const;

export default APP_CONFIG;
